/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { oneOfType, string, number } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import SkeletonStyles from './SkeletonStyles';

const useStyles = makeStyles({
    ...SkeletonStyles,
    root: (styles) => ({
        width: styles.width || '100%%',
        height: styles.height || '283px',
        display: 'flex',
        '@media screen and (max-width: 600px)': {
            height: styles.height || '140px',
        },
    }),
});
const GenericSkeleton = ({ height, width, className }) => {
    const classes = useStyles({ height, width });
    return (
        <div className={`${classes.root} ${classes.shimmer} ${className}`} />
    );
};

GenericSkeleton.propTypes = {
    width: oneOfType([string, number]),
    height: oneOfType([string, number]),
    className: string,
};

GenericSkeleton.defaultProps = {
    className: '',
    width: null,
    height: null,
};

export default GenericSkeleton;
