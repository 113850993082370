/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useLocation } from 'react-router-dom';
import mbpLogger from 'mbp-logger';
import { useSelector } from 'react-redux';
import qs from 'qs';
import { getFeatureFlag } from '../../../state/ducks/App/ducks/Config/Config-Selectors';

const useCheckedFacets = () => {
    const location = useLocation();
    const isAggregatorFacetErrorResolver = useSelector(getFeatureFlag('is-facet-throwing-error-resolver-enabled'));
    if (isAggregatorFacetErrorResolver && location?.search) {
        try {
            const decodedString = decodeURI(location?.search).replace('?', '');
            const queryParams = qs.parse(decodedString);
            let redirectLocationData = queryParams.facet;
            if (typeof queryParams.facet === 'string') {
                redirectLocationData = [queryParams.facet];
            }
            const checkedFacetsData = redirectLocationData?.length && redirectLocationData?.[0] !== '' ? redirectLocationData : [];
            return checkedFacetsData;
        } catch (e) {
            mbpLogger.logError({
                message: 'Malformed URI',
                error: e,
                module: 'useCheckedFacets',
                function: 'useCheckedFacets',
            });
        }
    } else if (location?.search) {
        const searchValue = unescape(location?.search);
        const filteredSearchValue = searchValue.split('?')?.filter((value) => value?.includes('facet='))?.[0];
        let checkedFacetsData = [];
        if (filteredSearchValue) {
            const locationData = filteredSearchValue?.replace('facet=', '');
            const redirectLocationData = locationData?.split('&');
            checkedFacetsData = redirectLocationData?.length && redirectLocationData?.[0] !== '' ? redirectLocationData : [];
        }
        return checkedFacetsData;
    }

    return [];
};

export default useCheckedFacets;
