/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { object, shape, string } from 'prop-types';
import { getBrand } from '../../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { trackEvent } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = {
    bannerOuterGrid: {
        position: 'relative',
        width: '100%',
        '& a': {
            '& img': {
                width: '100%',
            },
        },
    },
    nonClickableBanner: {
        width: 'inherit',
        display: 'block',
    },
    popUpBlock: {
        position: 'absolute',
        left: '80%',
        bottom: '40px',
        width: '610px',
    },
};
const GraphqlSideRailBanner = ({
    classes, bannerData = {}, brand,
}) => {
    const [showPopupBlock, setShowPopupBlock] = useState(false);
    if (!bannerData) {
        return null;
    }
    const href = bannerData.link?.href || '';
    const url = bannerData.image?.url || '';
    const alt = bannerData.image?.title || '';
    const id = bannerData.link?.title || '';
    const popupImageURL = bannerData?.hover_pop_up_image?.url || '';
    const popupImageAlt = bannerData?.hover_pop_up_image?.title || '';

    const dispatch = useDispatch();
    const trackBannerClick = () => {
        dispatch(trackEvent({
            eventCategory: bannerData?.tracking_event_category || '',
            eventAction: bannerData?.tracking_event_action || '',
            eventLabel: bannerData?.tracking_event_label || '',
        }));
    };

    const sideRailBannerLink = (href?.indexOf('http://') === 0 || href?.indexOf('https://') === 0)
        ? (
            <a href={href} id={`Side_Rail_Banner_${id}`} onClick={() => trackBannerClick()}>
                <img src={url} alt={alt} />
            </a>
        )
        : (
            <Link to={href} id={`Side_Rail_Banner_${id}`} onClick={() => trackBannerClick()}>
                <img src={url} alt={alt} />
            </Link>
        );
    const bannerLink = href?.includes('/blog') || href?.includes('/articles')
        ? (
            <a href={`https://www.${brand?.domain}.com${href}`} id={`Blog_Link_${id}`} onClick={() => trackBannerClick()}>
                <img src={url} alt={alt} />
            </a>
        ) : (
            sideRailBannerLink
        );
    const renderLink = href && url ? (bannerLink) : (
        <img
            src={url}
            alt={alt}
            className={classes.nonClickableBanner}
            role="presentation"
            onClick={() => popupImageURL !== '' && setShowPopupBlock(!showPopupBlock)}
        />
    );
    if (bannerData && bannerData !== null) {
        return (
            <Grid className={classes.bannerOuterGrid}>
                {renderLink}
                {popupImageURL !== '' && showPopupBlock && <img className={classes.popUpBlock} src={popupImageURL} alt={popupImageAlt} />}
            </Grid>

        );
    }

    return null;
};

GraphqlSideRailBanner.propTypes = {
    bannerData: shape({
        link: shape({
            href: string,
            title: string,
        }).isRequired,
        Image: shape({
            url: string,
            title: string,
        }),
    }).isRequired,
    classes: object.isRequired,
    brand: shape({
        domain: string.isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

export default connect(mapStateToProps, null)(withStyles(styles)(GraphqlSideRailBanner));
