/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, func, string } from 'prop-types';
import { Card, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import {  useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { getFeatureFlag } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getActiveABTest } from '../../../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';

const useStyles = makeStyles((theme) => ({
    outerCard: ({ styles }) => ({
        backgroundColor: styles.backgroundColor || theme.palette.guidedNav.background,
        padding: '20px',
        [theme.breakpoints.up(768)]: {
            margin: 0,
        },
        [theme.breakpoints.down(599)]: {
            padding: '20px 0px',
        },
    }),
    cardHeaderText: {
        fontSize: '18px',
        fontWeight: 'bold',
        [theme.breakpoints.up(768)]: {
            textTransform: 'uppercase',
            textAlign: 'center',
            fontWeight: '500',
        },
        [theme.breakpoints.down(599)]: {
            width: '100%',
            fontSize: '16px',
        },
    },
    submittedZip: {
        fontSize: '18px',
        fontWeight: 'bold',
        paddingLeft: '5px',

        [theme.breakpoints.up(768)]: {
            paddingLeft: '0',
            fontSize: '28px',
            textAlign: 'center',
            paddingTop: '40px',
            paddingBottom: '50px',
        },
    },
    startOver: ({ styles }) => ({
        verticalAlign: 'middle',
        fontSize: '16px',
        fontWeight: 'bold',
        textTransform: 'none',
        color: styles.submitButtonCopyColor || 'inherit',
        backgroundColor: styles.submitButtonColor || 'inherit',
        '&:hover': {
            color: styles.submitButtonCopyColor || 'inherit',
            backgroundColor: styles.submitButtonColor || 'inherit',
        },
        [theme.breakpoints.down(599)]: {
            border: 'inherit',
            padding: '0 15px',
        },
    }),
    infoTextHolder: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20px',
        '& p': {
            margin: 0,
        },
        [theme.breakpoints.up(768)]: {
            flexDirection: 'column',
            marginBottom: '0',
        },
        [theme.breakpoints.down(599)]: {
            width: '50%',
            justifyContent: 'left',
            marginBottom: 0,
        },
    },
    outerGrid: {
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.up(768)]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },

    newGnavDialogStyle: {
        '&$outerCard': {
            boxShadow: 'none',
            borderRadius: '3px',
        },
        '& $submittedZip, $cardHeaderText': {
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '19px',
            padding: '0px',
        },
        '& $infoTextHolder': {
            marginBottom: '12px',
            flexDirection: 'row',
        },
        '& $cardHeaderText': {
            margin: '0px 4px 0px 0px',
            textTransform: 'none',
        },
        [theme.breakpoints.down(600)]: {
            '& $outerGrid': {
                alignItems: 'center',
            },
            '& $cardHeaderText': {
                width: 'auto',
            },
            '& .pac-container': {
                borderTop: '0px',
            },
            '& $infoTextHolder': {
                marginBottom: '0px',
            },
        },
        '& $startOver': {
            border: 'none',
            padding: '8px 20px',
            borderRadius: '3px',
            fontWeight: '700',
            letterSpacing: '0.04em',
            lineHeight: '20px',
            fontSize: '0.875rem',
            '& .MuiTouchRipple-child': {
                background: 'transparent',
            },
        },
    },

}));

const GuidedNavCollapsedView = (props) => {
    const {
        reduxZip,
        handleStartOverClick,
        guidedNavFormData,
    } = props;
    const isMobile = useMediaQuery('(max-width: 600px)');

    const abNewGuidedNavFacelift = useSelector(getActiveABTest('new_guided_nav_facelift')) === 'variant';
    const isUpdatedGnav = (useSelector(getFeatureFlag('is-updated-gnav-presentation')) && abNewGuidedNavFacelift) || false;

    const styles = isUpdatedGnav ? {
        backgroundColor: guidedNavFormData?.background_color,
        submitButtonColor: guidedNavFormData?.submit_button_color,
        submitButtonCopyColor: guidedNavFormData?.submit_button_copy_color,
    } : {};
    const classes = useStyles({ styles });

    return (
        <Card className={`${classes.outerCard} ${isUpdatedGnav  ? classes.newGnavDialogStyle : ''}`}>
            <Grid container justifyContent="space-around" className={classes.outerGrid}>
                <Grid item container className={classes.infoTextHolder}>
                    <h3 className={classes.cardHeaderText}>
                        Showing gifts in:
                    </h3>
                    <p className={classes.submittedZip}>
                        {reduxZip}
                    </p>
                </Grid>
                <Grid item>
                    <Button
                        className={classes.startOver}
                        onClick={handleStartOverClick}
                        variant="outlined"
                    >
                        { (isMobile && !isUpdatedGnav) && <RefreshIcon />}
                        Start Over
                    </Button>
                </Grid>
            </Grid>
        </Card>
    );
};

GuidedNavCollapsedView.propTypes = {
    handleStartOverClick: func.isRequired,
    reduxZip: string.isRequired,
    guidedNavFormData: object,
};
GuidedNavCollapsedView.defaultProps = {
    guidedNavFormData: {},
};

export default GuidedNavCollapsedView;
