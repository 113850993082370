/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useSelector } from 'react-redux';

import { Grid, withStyles } from '@material-ui/core';
import {
    object, bool, func, arrayOf, shape, string,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import { useLocation } from 'react-router-dom';
import { getActiveABTest } from '../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import GraphqlGuidedNav from './Partials/GraphqlGuidedNav/GuidedNav';
import GraphqlCategoryRelatedBox from './Partials/GraphqlCategoryRelatedBox';
import GraphqlSideRailBanner from './Partials/GraphqlSideRailBanner';
import GraphqlProductFacets from '../GraphqlProductFacets/GraphqlProductFacets';
import { getFeatureFlag, getPresentationFamily } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import ScrollbarContainer from '../ScrollbarContainer/ScrollbarContainer';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.up(1024)]: {
            minHeight: '100%',
        },
    },
    leftMenuComponent: {
        display: 'none',
        [theme.breakpoints.up(769)]: {
            display: 'block',
            width: '100%',
            marginBottom: '15px',
        },
    },
    gnavLeftMenuContainer: {
        width: '100%',
        marginBottom: '15px',
    },
    productFacet: {
        margin: '20px 0px',
        [theme.breakpoints.down(1025)]: {
            display: 'none',
        },
    },
});

const GraphqlLeftMenuContainer = ({
    classes, contentGNavRef, guidedNavForm, leftMenuData, isMobile, trackEvent, isGuidedNavWidgetEnabledFlag, isFacetFiltersCountEnabled, setFilterDataInCategoryBuilder, productFacetsBase, isFoodBrand, isFacetFiltersEnabled, category,
}) => {
    const seed = useUIDSeed();
    const abGuidedNav = useSelector(getActiveABTest('guided-nav'));
    const isFloralStickyEnabled = useSelector(getFeatureFlag('is-floral-sticky-facet-enabled'));
    const presentationFamily = useSelector(getPresentationFamily);

    const location = useLocation();
    // guidedNavForm = old gnav data added to query through backend process
    // contentGNavRef = new gnav data, part of 'content' section of query
    const isNewGnavRefEnabled = useSelector(getFeatureFlag('is-gnav-content-ref-enabled'));
    let gNavData = guidedNavForm;
    if (isNewGnavRefEnabled && contentGNavRef) {
        gNavData = contentGNavRef;
    }

    const pageBlocks = typeof leftMenuData?.category_blocks !== 'undefined' || leftMenuData?.page_blocks;
    const leftMenuPageBlock = leftMenuData?.category_blocks || leftMenuData?.page_blocks || [];
    return (
        <Grid container direction="column" className={`${isFloralStickyEnabled && presentationFamily === 'flower' ? classes.container : ''} ${classes.leftMenuOuterGrid} leftMenu`}>
            {isGuidedNavWidgetEnabledFlag
            && gNavData
            && leftMenuData?.guided_nav?.show_guided_nav
            && abGuidedNav !== 'hide'
            && (
                <Grid item className={classes.gnavLeftMenuContainer}>
                    <GraphqlGuidedNav
                        guidedNavFormData={gNavData}
                        guidedNavLocationOverride={
                            leftMenuData.guided_nav.override_location_type
                        }
                    />
                </Grid>
            )}
            <ScrollbarContainer
                contentContainerId="productContainer"
                isFoodBrand={isFloralStickyEnabled}
                type="collection"
            >
                {!isFoodBrand  && isFacetFiltersEnabled  && productFacetsBase?.length > 0 && location.pathname !== '/flowers-gifts-400198087' && (
                    <div className={classes.productFacet}>
                        <GraphqlProductFacets
                            productFacets={productFacetsBase}
                            setFilterDataInCategoryBuilder={setFilterDataInCategoryBuilder}
                            isFoodBrand={isFoodBrand}
                            isFacetFiltersCountEnabled={isFacetFiltersCountEnabled}
                            track={trackEvent}
                            category={category}
                        />
                    </div>
                )}
                { pageBlocks && leftMenuPageBlock.map((block) => {
                    const blockKey = Object.keys(block)[0];
                    const blockObj = block[blockKey];
                    const GraphqlCategoryRelatedBoxBlock = (
                        <Grid
                            className={classes.leftMenuComponent}
                            key={seed(blockObj)}
                        >
                            <GraphqlCategoryRelatedBox relatedData={blockObj} trackEvent={trackEvent} />
                        </Grid>
                    );
                    // if anything gets added to this leftMenu switch make sure it contains classes.leftMenuComponent
                    // on tablet or less then < 768px grid item will display none i.e. classes.leftMenuComponent media query
                    switch (blockKey) {
                        case 'related_searches':
                            return (
                                !isMobile
                                    ? (
                                        GraphqlCategoryRelatedBoxBlock
                                    )
                                    : null
                            );
                        case 'related_articles':
                            return (!isMobile
                                ? (
                                    GraphqlCategoryRelatedBoxBlock
                                )
                                : null
                            );
                        case 'side_rail_banner':
                            return (
                                !isMobile
                                    ? (
                                        <Grid
                                            className={classes.leftMenuComponent}
                                            key={seed(blockObj)}
                                        >
                                            <GraphqlSideRailBanner bannerData={blockObj} />
                                        </Grid>
                                    )
                                    : null
                            );
                        default:
                            return null;
                    }
                })}
            </ScrollbarContainer>
        </Grid>
    );
};

GraphqlLeftMenuContainer.defaultProps = {
    category: {},
};

GraphqlLeftMenuContainer.propTypes = {
    classes: object.isRequired,
    contentGNavRef: object.isRequired,
    guidedNavForm: object.isRequired,
    leftMenuData: object.isRequired,
    isMobile: bool.isRequired,
    trackEvent: func.isRequired,
    isGuidedNavWidgetEnabledFlag: bool.isRequired,
    isFacetFiltersCountEnabled: bool.isRequired,
    isFacetFiltersEnabled: bool.isRequired,
    isFoodBrand: bool.isRequired,
    setFilterDataInCategoryBuilder: func.isRequired,
    productFacetsBase: arrayOf(
        shape({
            name: string.isRequired,
            entries: arrayOf(
                shape({
                    name: string.isRequired,
                    value: string.isRequired,
                }),
            ).isRequired,
        }).isRequired,
    ).isRequired,
    category: shape({
        name: string,
    }),
};

export default withStyles(styles)(GraphqlLeftMenuContainer);
