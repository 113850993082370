/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import { useUIDSeed } from 'react-uid';
import { Link } from 'react-router-dom';
import {
    object, shape, string, arrayOf, func,
} from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { getBrand } from '../../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';

const styles = (theme) => ({
    outerCard: {
        padding: '20px',
        borderRadius: 'unset',
        boxShadow: 'none',
        border: 1,
        borderColor: theme.palette.cms?.relatedArticlesDefaultBorderColor || '#636363',
        borderStyle: 'solid',
        color: theme.palette.cms?.relatedArticlesDefaultTextColor || '#212121',
    },
    linkText: {
        display: 'block',
        margin: 0,
        lineHeight: 1.5,
        cursor: 'pointer',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
        outline: 'none',
        padding: '3px 0 1px 0',
        fontSize: '14px',
        color: 'inherit',
        '&:hover': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
        '&:focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px',
        },
    },
    headerText: {
        '& p,& h2': {
            fontSize: '15px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            paddingBottom: '4px',
            lineHeight: '1.5',
            margin: 0,
        },
    },
    link: {
        textDecoration: 'none',
    },
    title: {
        cursor: 'context-menu',
        fontSize: 14,
    },
});

const leftRailLinks = (classes, line, title) => (line?.href.indexOf('http://') === 0 || line?.href.indexOf('https://') === 0
    ? <a href={line?.href} className={classes.linkText} aria-label={`${title} ${line?.title}`}>{line?.title}</a>
    : (
        <Link id={`relatedSearchProduct ${title} ${line?.title}`} to={line?.href} className={classes.linkText}>
            {line?.title}
        </Link>
    ));
const renderLinks = (classes, line, brand, title) => (line?.href?.includes('/blog') || line?.href?.includes('/articles')
    ? <a href={`https://www.${brand?.domain}.com${line?.href}`} className={classes.linkText} aria-label={`${title} ${line?.title}`}>{line?.title}</a>
    : (
        leftRailLinks(classes, line, title)
    ));
const trackRelatedCategory = (trackingLink, trackEventAction, title, line) => {
    let origin = '';
    if (typeof window !== 'undefined' && window.location && window.location.origin) {
        origin = window.location.origin;
    }
    trackEventAction({
        eventCategory: trackingLink?.tracking_event_category || 'Collection Page',
        eventAction: trackingLink?.tracking_event_action || `Left Nav - ${title}`,
        eventLabel: trackingLink?.tracking_event_label || line.href,
        eventName: 'select_side_nav',
        link_url: `${origin}${line?.href}`,
        link_text: line?.title || '',
    });
};
const GraphqlCategoryRelatedBox = ({
    classes, relatedData, brand, trackEvent,
}) => {
    const { title, link } = relatedData;
    const seed = useUIDSeed();
    const cardStyleOverride = {};
    cardStyleOverride.borderStyle = relatedData.border_style;
    cardStyleOverride.color = relatedData.font_color;
    cardStyleOverride.backgroundColor = relatedData.background_color;
    const trackingLink = relatedData.tracking_link ? relatedData.tracking_link : null;

    if (typeof link !== 'undefined' && link !== null) {
        return (
            <Card className={classes.outerCard} style={cardStyleOverride} data-testid="relatedArticleCardStyle">
                <Grid container direction="column">
                    <Grid item>
                        {/*
                            we want some headlines to be h2s for seo,
                            (normal string defaults to <p>, ## string returns <h2> from CMS)
                        */}
                        <ReactMarkdown
                            source={title}
                            skipHtml
                            className={classes.headerText}
                        />
                    </Grid>
                    <Grid item>
                        {link.map((line) => (
                            <Grid item key={seed(line)} onClick={line?.href ? () => trackRelatedCategory(trackingLink, trackEvent, title, line) : {}}>
                                {
                                    line?.href === ''
                                        ? <span className={classes.title}>{line?.title}</span>
                                        : renderLinks(classes, line, brand, title)
                                }
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Card>
        );
    }
    return null;
};

GraphqlCategoryRelatedBox.propTypes = {
    classes: object.isRequired,
    relatedData: shape({
        title: string.isRequired,
        link: arrayOf(shape({
            title: string.isRequired,
            href: string.isRequired,
        })).isRequired,
        trackingLink: shape({
            tracking_event_category: string,
            tracking_event_action: string,
            tracking_event_label: string,
        }),
    }).isRequired,
    brand: shape({
        domain: string.isRequired,
    }).isRequired,
    trackEvent: func.isRequired,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

export default connect(mapStateToProps, null)(withStyles(styles)(GraphqlCategoryRelatedBox));
